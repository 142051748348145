<template>
    <section v-editable="blok" class="hero-card-layout-header" :style="layoutHeaderStyles">
        <LayoutHeaderBackgroundImage
            :mobile-background-image="blok.mobileBackgroundImage"
            :background-image="blok.backgroundImage"
            :keep-image-background-original-quality="blok.keepImageBackgroundOriginalQuality"
        />
        <div class="content-container" :class="contentContainerClasses">
            <div class="title-wrap" :style="titleStyles" :class="blok.titleHideOn ? `hidden-${blok.titleHideOn}` : ''">
                <component :is="blok.titleHeadingType ? blok.titleHeadingType : 'h2'" v-if="blok.title" class="title">
                    {{ blok.title }}
                </component>
            </div>
            <div
                v-if="blok.subtitle"
                class="subtitle-wrap"
                :style="subtitleStyles"
                :class="blok.subtitleHideOn ? `hidden-${blok.subtitleHideOn}` : ''"
            >
                <h3 class="subtitle">{{ blok.subtitle }}</h3>
            </div>
            <template v-for="slot in blok.content" :key="slot._uid">
                <Component :is="slot.component" :blok="slot" :class="{ 'has-image': blok.backgroundImage.filename }" />
            </template>
        </div>
        <div class="additional-image">
            <div class="additional-image-container">
                <img
                    v-if="blok?.additional_image?.filename"
                    class="additional-image-img"
                    :src="blok.additional_image.filename"
                    alt=""
                />
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import type { IHeroCardLayoutHeader } from '~ui/types/components/HeroCardLayout/LayoutHeader';
import LayoutHeaderBackgroundImage from '~ui/components/HeroCardLayout/Components/LayoutHeaderBackgroundImage.vue';

const props = defineProps<{ blok: IHeroCardLayoutHeader }>();

const titleStyles = computed(() => ({
    color: props.blok.titleColor ? props.blok.titleColor?.color : null,
    fontSize: props.blok.titleFontSize ? `${props.blok.titleFontSize}rem` : null,
    fontWeight: props.blok.titleFontWeight ? props.blok.titleFontWeight : null,
}));

const subtitleStyles = computed(() => ({
    color: props.blok.subtitleColor ? props.blok.subtitleColor?.color : null,
    fontSize: props.blok.subtitleFontSize ? `${props.blok.subtitleFontSize}rem` : null,
    fontWeight: props.blok.subtitleFontWeight ? props.blok.subtitleFontWeight : null,
}));

const contentContainerClasses = computed(() => ({
    'has-additional-image': !!props.blok?.additional_image?.filename,
}));

const img = useImage();
const imgUrl = props.blok.backgroundImage?.filename
    ? img(props.blok.backgroundImage?.filename, { format: 'webp', height: 500 }, { provider: 'storyblok' })
    : null;

const layoutHeaderStyles = computed(() => {
    return {
        '--background-desktop-image': `url('${imgUrl}')`,
    };
});
</script>

<style lang="postcss" scoped>
.hero-card-layout-header {
    @apply relative overflow-hidden bg-[#1E86C2];
}
.hidden-desktop {
    @apply md:hidden;
}
.hidden-mobile {
    @apply max-md:hidden;
}
.title,
.subtitle {
    @apply text-[63%] xl:max-w-[85%];
    @media screen(md) {
        @apply text-[75%];
    }
    @media screen(lg) {
        @apply text-[100%];
    }
}

.content-container {
    @apply relative mx-auto px-4 max-w-[1300px] z-[1];
    @apply xl:min-h-28;
    :deep(.breadcrumbs) > div {
        max-width: 100%;
    }
}
.additional-image {
    @apply hidden xl:block absolute bottom-[68px] w-full pointer-events-none;
}
.additional-image-container {
    @apply px-5 max-w-[1300px] mx-auto;
    @apply flex justify-end h-44;
}
</style>
