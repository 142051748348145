<template>
    <div class="hero-image-wrapper">
        <NuxtImg
            v-if="isMobileView && mobileBackgroundImage?.filename"
            :src="mobileBackgroundImage.filename"
            :alt="mobileBackgroundImage.alt"
            provider="storyblok"
            sizes="100vw sm:250px 25vw md:468px 50vw lg:1024px"
            format="webp"
            class="hero-image"
            densities="x1 x2"
            preload
        />
        <NuxtImg
            v-else-if="!isMobileView && backgroundImage?.filename && !keepImageBackgroundOriginalQuality"
            :src="backgroundImage.filename"
            :alt="backgroundImage.alt"
            provider="storyblok"
            sizes="100vw sm:250px 25vw md:468px 50vw lg:1024px"
            format="webp"
            class="hero-image"
            densities="x1 x2"
            preload
        />
        <NuxtImg
            v-else-if="!isMobileView && backgroundImage?.filename && keepImageBackgroundOriginalQuality"
            :src="backgroundImage.filename"
            :alt="backgroundImage.alt"
            provider="storyblok"
            class="hero-image"
            preload
        />
    </div>
</template>

<script setup lang="ts">
import { useIsMobileView } from '~ui/composables/isMobileView';
import { IAssetImage } from '~ui/types/components/AssetImage';

const props = defineProps<{
    mobileBackgroundImage: IAssetImage;
    backgroundImage: IAssetImage;
    keepImageBackgroundOriginalQuality: boolean;
}>();

const { isMobileView } = useIsMobileView('768px');
</script>

<style scoped lang="postcss">
.hero-image {
    @apply absolute top-0 bottom-0 left-0 right-0 w-[200vw] h-full min-h-[500px] object-cover z-[0];
}
</style>
